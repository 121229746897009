<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px">

      <el-form-item class="art-editor" label="关于超律志：" prop="content" label-width="100px">
<!--        <Editor :showBtn="true" :content.sync="formData.about_us"></Editor>-->
        <ueditor @changeContent="changeAboutUs" :content.sync="formData.about_us"></ueditor>
      </el-form-item>

      <el-form-item class="art-editor" label="关于超律志法律翻译：" prop="about_us_product" label-width="100px">
<!--        <Editor :showBtn="true" :content.sync="formData.about_us_product"></Editor>-->
        <ueditor @changeContent="changeAboutUsProduct" :content.sync="formData.about_us_product"></ueditor>
      </el-form-item>

      <el-form-item class="art-editor" label="关于超律志图片：" prop="about_us_product" label-width="100px">
          <file-upload-img :url.sync="formData.about_us_img"></file-upload-img>
          <span>(385 * 180)</span>
      </el-form-item>

      <el-form-item class="art-editor" label="是否展示图片" prop="is_open_us_img" label-width="100px">
      <el-radio-group v-model="formData.is_open_us_img" size="small">
          <el-radio-button label=0>隐藏</el-radio-button>
          <el-radio-button label="1">展示</el-radio-button>
      </el-radio-group>

       </el-form-item>

      <el-form-item class="art-editor" label="特色和优势：" prop="feature" label-width="100px">
        <el-button style="margin-top: 10px" @click="addRow">新增特色</el-button>
        <el-table :data="formData.feature" style="width: 100%">
          <el-table-column label="特色和优势标题" prop="title">
            <template slot-scope="scope">
              <el-input v-model="scope.row.title"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="特色和优势图片(420 * 160)" prop="url">
            <template slot-scope="scope">
              <file-upload-img :url.sync="scope.row.url"></file-upload-img>
            </template>
          </el-table-column>
          <el-table-column label="Operation">
            <template slot-scope="scope">
              <el-button type="danger" @click="removeRow(scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label=" ">
        <el-button size="small" type="" @click="cancel">取消</el-button>
        <el-button size="small" type="primary" @click="save">保存</el-button>
      </el-form-item>
    </el-form>
    <div style="height: 40px"></div>
  </div>
</template>

<script>
import { getDetailAPI, editAPI } from './api'
import FileUploadImg from '@/components/uploader/uploader-img.vue'

import Editor from '@/components/editor/index.vue'

export default {
  name: 'List',
  components: { Editor, FileUploadImg },
  data() {
    return {
      formData: {
        about_us: '',
        about_us_product: '',
        about_us_img: '',
        is_open_us_img: 0,
        feature: [
          {
            title: '',
            url:''
          }
        ]
      },
    }
  },
  watch: {
    activeName: {
      immediate: true,
      handler(newV, oldV) {
        this.getDetail()
      }
    }
  },
  mounted() {},
  computed: {
    title() {
      let name = '关于我们'
      return name
    }
  },
  methods: {
    changeAboutUs(val) {
        this.formData.about_us = val
    },
    changeAboutUsProduct(val) {
        this.formData.about_us_product = val
    },
    addRow() {
      this.formData.feature.push({ title: '', url:'' });
    },
    removeRow(index) {
      this.formData.feature.splice(index, 1);
    },
    addInput() {
      this.formData.feature.push({ title: '', url:'' });
    },
    removeInput(index) {
      this.formData.feature.splice(index, 1);
    },
    async getDetail() {
      this.formData = await getDetailAPI()
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
    save() {
      editAPI(this.formData).then(() => {
        this.$message.success('保存成功')
        this.getDetail()
      })
    },
    cancel() {
      this.getDetail()
      // 初始化编辑器
      this.$refs.editor.init()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wrapper {
  width: 100%;
  .lease-content {
    display: flex;
    .left {
      width: 150px;
      .el-tabs /deep/ {
        .el-tabs__nav-wrap.is-left::after {
          width: 0;
        }
      }
    }
    .right {
      flex: 1;
      .title {
        margin-bottom: 10px;
        margin-top: 8px;
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
}
</style>
