import { http } from '@/http/axios.js'
export function getDetailAPI() {
  return http({
    url: '/company/about_us/detail',
    method: 'get'
  })
}
export function editAPI(data) {
  return http({
    url: '/company/about_us/editOrUpdate',
    method: 'post',
    data
  })
}
